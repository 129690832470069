import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { eventDefaultValuesModel } from 'models/eventDefaultValuesModel';
import { getPerformanceDateTime } from 'utils/dates';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  eventInfo: null,
  activeTab: 'details',

  performances: {
    eventPerformances: [],
    currentPage: 1,
  },

  eventLoadingStatus: status.IDLE,
};

const slice = createSlice({
  name: 'eventDetails',
  initialState,
  reducers: {
    setActiveTab(state, { payload }) {
      state.activeTab = payload;
    },
    setPerformancesPage(state, { payload }) {
      state.performances.currentPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getEventInfo.pending, (state) => {
        state.eventLoadingStatus = status.PENDING;
      })
      .addCase(thunks.getEventInfo.fulfilled, (state, { payload }) => {
        state.eventInfo = eventDefaultValuesModel(payload.data);
        state.eventLoadingStatus = status.SUCCESS;
      })

      .addCase(thunks.getEventPerformances.fulfilled, (state, { payload }) => {
        const data = payload?.data;

        const preparedData = data?.map((p) => ({
          name: p.routine_data.title,
          dateTime: getPerformanceDateTime(
            p.userLocalDateFormatted,
            p.userLocalTimeFormatted
          ),
          location: p?.event_data?.location_formatted,
          ...p,
        }));

        state.performances.eventPerformances = preparedData || [];
      });
  },
});

const eventDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { eventDetails };
export default slice.reducer;

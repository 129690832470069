import { format, fromUnixTime } from 'date-fns';

export const dateFromUnixToString = (unixDate, formatString) => {
  if (!formatString) {
    throw new Error('formatString is required');
  }

  if (unixDate && typeof unixDate === 'number') {
    // format unix to seconds
    return format(fromUnixTime(unixDate / 1000), formatString);
  }

  return null;
};

export const mergeDateWithTime = (date, time) => {
  const [startHours, startMinutes] = time.split(':').map(Number);
  const newDate = new Date(date);
  newDate.setHours(startHours, startMinutes);
  return newDate;
};

export const getPerformanceDateTime = (date, time) => {
  if (!date) return time;
  if (!time) return date;

  return `${date}; ${time}`;
};
